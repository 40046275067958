// import login from '@/api/login.js'
import { Message } from 'element-ui'

/* 退出登陆 */
export function logOut() {
    const token = localStorage.getItem('token')
    if (token) {
        login.logout(token).then(res => {
            if (res.success) {
                Message.success(res.message)
                localStorage.removeItem('token')
                localStorage.removeItem('userInfo')
                console.log('退出登陆成功!')
                // window.location.href = '/login'
            }
        })
    }
}
/* jsonp请求 */ 
export function jsonp(url) {
    return new Promise((resolve,reject) => {
        window.jsonCallBack =(result) => {
            resolve(result)
        }
        var JSONP=document.createElement("script");
        JSONP.type="text/javascript";
        JSONP.src=`${url}&callback=jsonCallBack`;
        document.getElementsByTagName("head")[0].appendChild(JSONP);
        setTimeout(() => {
            document.getElementsByTagName("head")[0].removeChild(JSONP)
        },500)
    })
}