<template>
    <div class="meteor">
        <Header class="hide" />
    <Headers class="dispy" />
        <div class="bar">
            <BannerImage rulename="query_banner" />
            <div class="w">
                <Breadcrumb :crumblist="crumbdata" class="hide" />
                <div class="meteor-container clear">
                    <h2 class="nav-title">崇信县七天天气</h2>
                    <div class="meteor-list">
                        <el-row :gutter="50">
                            <el-col :md="8" v-for="(item,index) in result" :key="index">
                                <div class="meteor-item">
                                    <div class="time clear">
                                        <span class="lf">{{ item.date }}</span>
                                        <span class="lr">{{ item.week }}</span>
                                    </div>
                                    <div class="weather">
                                        <img v-if="item.wea == '晴'" src="@/assets/image/main/weather/fine.png" class="weather-image">
                                        <img v-else-if="item.wea == '阴'" src="@/assets/image/main/weather/yin.png" class="weather-image">
                                        <img v-else-if="item.wea == '小雨转晴'" src="@/assets/image/main/weather/light_rain_turned.png" class="weather-image">
                                        <img v-else-if="item.wea == '晴转多云'" src="@/assets/image/main/weather/sunny_to_cloudy.png" class="weather-image">
                                        <img v-else-if="item.wea == '多云转阴'" src="@/assets/image/main/weather/cloudy_to_cloudy.png" class="weather-image">
                                        <img v-else-if="item.wea == '多云转晴'" src="@/assets/image/main/weather/cloudy_to_clear.png" class="weather-image">
                                        <img v-else src="@/assets/image/main/weather/fine.png" class="weather-image">
                                        <p class="sun">{{ item.wea }} {{ item.air_level }}</p>
                                        <p class="degrees"><span class="low">{{ item.tem2 }}</span>~<span class="high">{{ item.tem1 }}</span></p>
                                    </div>
                                </div>
                                <!-- {{ item }} -->
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import Footer from "@/components/main/Footer";
import { getAction } from "@/api/manage";
import { jsonp } from '@/utils/index'
import BannerImage from "@/components/main/BannerImage";
export default {
    components: {
        Header,
        Footer,
        Breadcrumb,
        BannerImage,
        Headers
    },
    created() {
        this.loadData();
    },
    data() {
        return {
            crumbdata: [
                { name: "信息查询", url: "" },
                { name: "气象服务", url: "/meteor" },
            ],
            result: [],
        };
    },
    methods: {
        loadData() {
            jsonp("https://tianqiapi.com/api?version=v1&appid=88284645&appsecret=XXvfWpm9&city=平凉").then(res => {
                this.result = res.data
            })
        },
    },
};
</script>
<style lang="scss" scoped>
.meteor-container{
    .nav-title{
        text-align: center;
        background-color: #6EB400;
        color: #fff;
        line-height: 50px;
        letter-spacing: 2px;
        margin-bottom: 20px;
    }
    .meteor-list{
        padding: 0 100px;
        .meteor-item{
            background-color: #fff;
            border: 1px solid #E5E5E5;
            text-align: center;
            margin-bottom: 50px;
            .time{
                background-color: #f5f5f5;
                padding: 10px 20px;
                color: #6EB400;
                font-size: 16px;
            }
            .weather{
                .weather-image{
                    width: 100px;
                    margin-top: 20px;
                }
                p{
                    margin: 20px 0;
                }
                .degrees{
                    .low{
                        color: #3388FF;
                    }
                    .high{
                        color: #EB111B;
                    }
                }
            }
        }
    }
}
</style>
